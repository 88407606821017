<template>
  <div class="contact">
    <titles></titles>
    <div class="banner">
      <div class="contact_banner_txt">
        <div class="contact_banner_n_box">
          <div class="contact_bg_txt_box">
            <h2>BANGMANG</h2>
            <div class="contact_sm_txt">
              <h3>联系我们</h3>
              <p>共建人力资源生态系统</p>
            </div>

            <div class="banner_btn">立即联系</div>
          </div>
        </div>
      </div>
      <img src="@/assets/contact_banner.jpg" />
    </div>
    <div class="contact_body">
      <div class="all_box">
        <div class="contact_all_box">
          <div class="contact_left">
            <div class="contact_phone_box">
              <img src="@/assets/contact_us.png" />
              <h4>400-891-5050</h4>
              <p>业务辐射全国400+城市</p>
            </div>
            <!-- <div class="contact_erweima">
              <img src="@/assets/erweima.jpg" />
            </div> -->
            <div class="contact_add_list" style="margin-top:110px">
              <div class="contact_add_item">
                <img src="@/assets/add_icon.png" />
                服务热线：400-891-5050
              </div>
              <div class="contact_add_item">
                <img src="@/assets/add_icon.png" />
                商务合作：yy@50bm.com
              </div>
              <div class="contact_add_item">
                <img src="@/assets/add_icon.png" />
                总部：浙江省嘉兴市平湖市当湖街道漕兑路89号总商会大厦B座8层
              </div>
            </div>
          </div>
          <div class="contact_right">
            <div class="contact_message_box">
              <message></message>
            </div>
          </div>
        </div>
      </div>
    </div>
    <check :type="3" :sCode="sCode" :qCode="qCode" :compname="compname"></check>

    <foots :type="3"></foots>
  </div>
</template>
<script>
import message from "@/components/message.vue";
import check from "@/components/check.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
export default {
  name: "Contact",
  components: {
    titles,
    foots,
    check,
    message,
  },
  mounted() {
    if (this.$route.query.sCode || this.$route.query.qCode || this.$route.query.compname) {
      this.sCode = this.$route.query.sCode;
      this.qCode = this.$route.query.qCode;
      this.compname = this.$route.query.compname;
    } else {
      window.scrollTo(0, 0);
    }


  },
  data() {
    return {
      form: "",
      messageData: {},
      dataForm: {},
      compname: "",
      qCode: "",
      sCode: "",
      options: {
        sList: [],
        qList: [],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.banner_btn {
  margin: 0 auto;
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  // margin-top: 145px;
  margin-top: 115px;
}

.contact_banner_txt {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -200px;

  .contact_banner_n_box {
    width: 80%;
    margin: 0 auto;
  }

  .contact_bg_txt_box {
    display: table;
    position: relative;
  }

  h2 {
    font-size: 111px;
    color: #ffd370;
    opacity: 0.1;
  }

  .contact_sm_txt {
    position: absolute;
    width: 100%;
    top: 115px;
    left: 0;
    color: #fff;

    h3 {
      font-size: 55px;
      margin: 0;
      margin-bottom: 50px;
    }

    p {
      font-size: 24px;
    }
  }
}

.banner {
  position: relative;
}

.contact_message_box {}

.select_item_box {
  margin-left: 10px !important;
}

.contact_such {
  background: url(../assets/check_bg.jpg) center center no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;

  .contact_such_title {
    text-align: left;
    margin-bottom: 45px;

    h3 {
      font-size: 36px;
      color: #ffffff;
      font-weight: lighter;
      margin: 0;
      margin-bottom: 22px;

      span {
        font-size: 18px;
      }
    }

    h4 {
      font-size: 16px;
      color: #fff;
      margin: 0;
      margin-bottom: 30px;
    }

    .henxian {
      width: 48px;
      height: 1px;
      background: #fff;
    }
  }
}

.contact_erweima {
  text-align: left;
  margin-top: 190px;
  margin-bottom: 60px;
}

.contact_add_list {
  text-align: left;

  .contact_add_item {
    font-size: 16px;
    color: #333333;
    margin: 22px 0;
    height: 26px;
    line-height: 26px;

    img {
      margin-bottom: -9px;
      margin-right: 5px;
    }
  }
}

.contact_body {
  padding: 110px 0 100px;
}

.contact_phone_box {
  text-align: left;

  img {}

  h4 {
    font-size: 42px;
    color: #d70035;
    margin: 0;
    margin-top: 25px;
  }

  p {
    font-size: 22px;
    color: #969696;
    margin: 0;
    margin-top: 25px;
  }
}

.contact_all_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact_left {
  width: 60%;
}

.contact_right {
  width: 40%;
}

.contact_message_box {
  background: #eaeaea;
  padding: 45px 80px;
}</style>